import { BaseModel } from 'shared/models/base.model';

class Location extends BaseModel {
  type: number;
  cityName: string;
  displayName: string;
  stateProvinceCode: string;
  stateProvinceName: string;
  countryCode: string;
  countryName: string;
  coordinate: Coordinate;
  zipCodes?: string[];

  constructor(json?: any) {
    super(json);
    if (json?.zipCodes) {
      this.zipCodes = json.zipCodes;
    }
  }
}

export class LocationResult {
  results: Location[];

  constructor(json?: any) {
    if (json?.results) {
      this.results = json.results.map(result => new Location(result));
    }
  }
}


