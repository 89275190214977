import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { LocationResult } from 'shared/models/geography/location.model';
import { GeoPosition } from 'shared/components/azure-map/Types';

@Service()
export class GeographyRepository extends CacheableRepository {
  @Inject('apiConfig.geographyAPI')
  protected baseUrl;

  searchForLocation(criteria: GeographySearchWrapper<LocationSearchCriteria, SearchOptions<any>>): Observable<LocationResult[]> {
    const searchText = criteria.criteria.searchText;
    const locationTypeFilter = criteria.criteria.locationTypeFilter;
    const numberOfResults = criteria.searchOptions.pageSize;
    const sortField = criteria.searchOptions.sortCriterion.field;
    const sortDirection = criteria.searchOptions.sortCriterion.direction;

    return this.get(`/Locations?search.criteria.searchText=${searchText}&search.criteria.locationTypeFilter=${locationTypeFilter}&search.searchOptions.pageSize=${numberOfResults}&search.searchOptions.sortCriterion.field=${sortField}&search.searchOptions.sortCriterion.direction=${sortDirection}`)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(results => results ? [new LocationResult(results)] : []);
  }

  getMapRoute(geoCoordinates: GeoPosition[]): Observable<GeoPosition[]> {
    return this.post('/routes', { geoCoordinates })
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(results => results ? results.route.shape : []);
  }

  getTimeZone(latitude: number, longitude: number, city: string, countryCode: string, stateProvinceCode: string): Observable<string> {
    const path = `/TimeZones?search.coordinates.latitude=${latitude}&search.coordinates.longitude=${longitude}&search.city=${city}&search.countryCode=${countryCode}&search.stateProvinceCode=${stateProvinceCode}`;
    return this.getWithCache(path);
  }
}
